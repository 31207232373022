.mapboxgl-map {
  font-family: Inter, sans-serif !important;
}

.mapboxgl-popup {
  .mapboxgl-popup-content {
    padding: 0;
    border-radius: 6px;
  }
}
