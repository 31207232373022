/* inter-latin-100-normal */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: block;
  font-weight: 100;
  src: url(../../../node_modules/@fontsource/inter/files/inter-latin-100-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/inter/files/inter-latin-100-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* inter-latin-200-normal */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: block;
  font-weight: 200;
  src: url(../../../node_modules/@fontsource/inter/files/inter-latin-200-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/inter/files/inter-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* inter-latin-300-normal */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: block;
  font-weight: 300;
  src: url(../../../node_modules/@fontsource/inter/files/inter-latin-300-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/inter/files/inter-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* inter-latin-400-normal */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(../../../node_modules/@fontsource/inter/files/inter-latin-400-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/inter/files/inter-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* inter-latin-500-normal */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: block;
  font-weight: 500;
  src: url(../../../node_modules/@fontsource/inter/files/inter-latin-500-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/inter/files/inter-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* inter-latin-600-normal */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: block;
  font-weight: 600;
  src: url(../../../node_modules/@fontsource/inter/files/inter-latin-600-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/inter/files/inter-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* inter-latin-700-normal */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: block;
  font-weight: 700;
  src: url(../../../node_modules/@fontsource/inter/files/inter-latin-700-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/inter/files/inter-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* inter-latin-800-normal */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: block;
  font-weight: 800;
  src: url(../../../node_modules/@fontsource/inter/files/inter-latin-800-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/inter/files/inter-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* inter-latin-900-normal */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: block;
  font-weight: 900;
  src: url(../../../node_modules/@fontsource/inter/files/inter-latin-900-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/inter/files/inter-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* open-sans-latin-300-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: block;
  font-weight: 300;
  src: url(../../../node_modules/@fontsource/open-sans/files/open-sans-latin-300-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/open-sans/files/open-sans-latin-300-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* open-sans-latin-400-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(../../../node_modules/@fontsource/open-sans/files/open-sans-latin-400-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/open-sans/files/open-sans-latin-400-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* open-sans-latin-500-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: block;
  font-weight: 500;
  src: url(../../../node_modules/@fontsource/open-sans/files/open-sans-latin-500-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/open-sans/files/open-sans-latin-500-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* open-sans-latin-600-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: block;
  font-weight: 600;
  src: url(../../../node_modules/@fontsource/open-sans/files/open-sans-latin-600-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/open-sans/files/open-sans-latin-600-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* open-sans-latin-700-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: block;
  font-weight: 700;
  src: url(../../../node_modules/@fontsource/open-sans/files/open-sans-latin-700-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/open-sans/files/open-sans-latin-700-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* open-sans-latin-800-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: block;
  font-weight: 800;
  src: url(../../../node_modules/@fontsource/open-sans/files/open-sans-latin-800-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/open-sans/files/open-sans-latin-800-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* manrope-latin-wght-normal */
@font-face {
  font-family: 'Manrope Variable';
  font-style: normal;
  font-display: block;
  font-weight: 200 800;
  src: url(../../../node_modules/@fontsource-variable/manrope/files/manrope-latin-wght-normal.woff2)
    format('woff2-variations');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* lato-latin-100-normal */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: block;
  font-weight: 100;
  src: url(../../../node_modules/@fontsource/lato/files/lato-latin-100-normal.woff2) format('woff2'),
    url(../../../node_modules/@fontsource/lato/files/lato-latin-100-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* lato-latin-300-normal */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: block;
  font-weight: 300;
  src: url(../../../node_modules/@fontsource/lato/files/lato-latin-300-normal.woff2) format('woff2'),
    url(../../../node_modules/@fontsource/lato/files/lato-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* lato-latin-400-normal */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(../../../node_modules/@fontsource/lato/files/lato-latin-400-normal.woff2) format('woff2'),
    url(../../../node_modules/@fontsource/lato/files/lato-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* lato-latin-700-normal */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: block;
  font-weight: 700;
  src: url(../../../node_modules/@fontsource/lato/files/lato-latin-700-normal.woff2) format('woff2'),
    url(../../../node_modules/@fontsource/lato/files/lato-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* lato-latin-900-normal */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: block;
  font-weight: 900;
  src: url(../../../node_modules/@fontsource/lato/files/lato-latin-900-normal.woff2) format('woff2'),
    url(../../../node_modules/@fontsource/lato/files/lato-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* merriweather-latin-300-normal */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: block;
  font-weight: 300;
  src: url(../../../node_modules/@fontsource/merriweather/files/merriweather-latin-300-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/merriweather/files/merriweather-latin-300-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* merriweather-latin-400-normal */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(../../../node_modules/@fontsource/merriweather/files/merriweather-latin-400-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/merriweather/files/merriweather-latin-400-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* merriweather-latin-700-normal */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: block;
  font-weight: 700;
  src: url(../../../node_modules/@fontsource/merriweather/files/merriweather-latin-700-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/merriweather/files/merriweather-latin-700-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* merriweather-latin-900-normal */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: block;
  font-weight: 900;
  src: url(../../../node_modules/@fontsource/merriweather/files/merriweather-latin-900-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/merriweather/files/merriweather-latin-900-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* montserrat-latin-100-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: block;
  font-weight: 100;
  src: url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-100-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-100-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* montserrat-latin-200-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: block;
  font-weight: 200;
  src: url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-200-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-200-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* montserrat-latin-300-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: block;
  font-weight: 300;
  src: url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-300-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-300-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* montserrat-latin-400-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-400-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-400-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* montserrat-latin-500-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: block;
  font-weight: 500;
  src: url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-500-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-500-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* montserrat-latin-600-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: block;
  font-weight: 600;
  src: url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-600-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-600-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* montserrat-latin-700-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: block;
  font-weight: 700;
  src: url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-700-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-700-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* montserrat-latin-800-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: block;
  font-weight: 800;
  src: url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-800-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-800-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* montserrat-latin-900-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: block;
  font-weight: 900;
  src: url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-900-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/montserrat/files/montserrat-latin-900-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* playfair-display-latin-wght-normal */
@font-face {
  font-family: 'Playfair Display Variable';
  font-style: normal;
  font-display: block;
  font-weight: 400 900;
  src: url(../../../node_modules/@fontsource-variable/playfair-display/files/playfair-display-latin-wght-normal.woff2)
    format('woff2-variations');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* poppins-latin-100-normal */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: block;
  font-weight: 100;
  src: url(../../../node_modules/@fontsource/poppins/files/poppins-latin-100-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/poppins/files/poppins-latin-100-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* poppins-latin-200-normal */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: block;
  font-weight: 200;
  src: url(../../../node_modules/@fontsource/poppins/files/poppins-latin-200-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/poppins/files/poppins-latin-200-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* poppins-latin-300-normal */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: block;
  font-weight: 300;
  src: url(../../../node_modules/@fontsource/poppins/files/poppins-latin-300-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/poppins/files/poppins-latin-300-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* poppins-latin-400-normal */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(../../../node_modules/@fontsource/poppins/files/poppins-latin-400-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/poppins/files/poppins-latin-400-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* poppins-latin-500-normal */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: block;
  font-weight: 500;
  src: url(../../../node_modules/@fontsource/poppins/files/poppins-latin-500-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/poppins/files/poppins-latin-500-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* poppins-latin-600-normal */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: block;
  font-weight: 600;
  src: url(../../../node_modules/@fontsource/poppins/files/poppins-latin-600-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/poppins/files/poppins-latin-600-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* poppins-latin-700-normal */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: block;
  font-weight: 700;
  src: url(../../../node_modules/@fontsource/poppins/files/poppins-latin-700-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/poppins/files/poppins-latin-700-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* poppins-latin-800-normal */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: block;
  font-weight: 800;
  src: url(../../../node_modules/@fontsource/poppins/files/poppins-latin-800-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/poppins/files/poppins-latin-800-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* poppins-latin-900-normal */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: block;
  font-weight: 900;
  src: url(../../../node_modules/@fontsource/poppins/files/poppins-latin-900-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/poppins/files/poppins-latin-900-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* raleway-latin-100-normal */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: block;
  font-weight: 100;
  src: url(../../../node_modules/@fontsource/raleway/files/raleway-latin-100-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/raleway/files/raleway-latin-100-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* raleway-latin-200-normal */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: block;
  font-weight: 200;
  src: url(../../../node_modules/@fontsource/raleway/files/raleway-latin-200-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/raleway/files/raleway-latin-200-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* raleway-latin-300-normal */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: block;
  font-weight: 300;
  src: url(../../../node_modules/@fontsource/raleway/files/raleway-latin-300-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/raleway/files/raleway-latin-300-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* raleway-latin-400-normal */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(../../../node_modules/@fontsource/raleway/files/raleway-latin-400-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/raleway/files/raleway-latin-400-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* raleway-latin-500-normal */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: block;
  font-weight: 500;
  src: url(../../../node_modules/@fontsource/raleway/files/raleway-latin-500-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/raleway/files/raleway-latin-500-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* raleway-latin-600-normal */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: block;
  font-weight: 600;
  src: url(../../../node_modules/@fontsource/raleway/files/raleway-latin-600-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/raleway/files/raleway-latin-600-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* raleway-latin-700-normal */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: block;
  font-weight: 700;
  src: url(../../../node_modules/@fontsource/raleway/files/raleway-latin-700-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/raleway/files/raleway-latin-700-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* raleway-latin-800-normal */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: block;
  font-weight: 800;
  src: url(../../../node_modules/@fontsource/raleway/files/raleway-latin-800-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/raleway/files/raleway-latin-800-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* raleway-latin-900-normal */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: block;
  font-weight: 900;
  src: url(../../../node_modules/@fontsource/raleway/files/raleway-latin-900-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/raleway/files/raleway-latin-900-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-latin-100-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: block;
  font-weight: 100;
  src: url(../../../node_modules/@fontsource/roboto/files/roboto-latin-100-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto/files/roboto-latin-100-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-latin-300-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: block;
  font-weight: 300;
  src: url(../../../node_modules/@fontsource/roboto/files/roboto-latin-300-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto/files/roboto-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-latin-400-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(../../../node_modules/@fontsource/roboto/files/roboto-latin-400-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto/files/roboto-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-latin-500-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: block;
  font-weight: 500;
  src: url(../../../node_modules/@fontsource/roboto/files/roboto-latin-500-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto/files/roboto-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-latin-700-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: block;
  font-weight: 700;
  src: url(../../../node_modules/@fontsource/roboto/files/roboto-latin-700-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto/files/roboto-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-latin-900-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: block;
  font-weight: 900;
  src: url(../../../node_modules/@fontsource/roboto/files/roboto-latin-900-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto/files/roboto-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-slab-latin-100-normal */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-display: block;
  font-weight: 100;
  src: url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-100-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-100-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-slab-latin-200-normal */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-display: block;
  font-weight: 200;
  src: url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-200-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-200-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-slab-latin-300-normal */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-display: block;
  font-weight: 300;
  src: url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-300-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-300-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-slab-latin-400-normal */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-400-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-400-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-slab-latin-500-normal */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-display: block;
  font-weight: 500;
  src: url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-500-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-500-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-slab-latin-600-normal */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-display: block;
  font-weight: 600;
  src: url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-600-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-600-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-slab-latin-700-normal */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-display: block;
  font-weight: 700;
  src: url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-700-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-700-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-slab-latin-800-normal */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-display: block;
  font-weight: 800;
  src: url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-800-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-800-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* roboto-slab-latin-900-normal */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-display: block;
  font-weight: 900;
  src: url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-900-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/roboto-slab/files/roboto-slab-latin-900-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* source-sans-pro-latin-200-normal */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-display: block;
  font-weight: 200;
  src: url(../../../node_modules/@fontsource/source-sans-pro/files/source-sans-pro-latin-200-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/source-sans-pro/files/source-sans-pro-latin-200-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* source-sans-pro-latin-300-normal */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-display: block;
  font-weight: 300;
  src: url(../../../node_modules/@fontsource/source-sans-pro/files/source-sans-pro-latin-300-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/source-sans-pro/files/source-sans-pro-latin-300-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* source-sans-pro-latin-400-normal */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(../../../node_modules/@fontsource/source-sans-pro/files/source-sans-pro-latin-400-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/source-sans-pro/files/source-sans-pro-latin-400-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* source-sans-pro-latin-600-normal */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-display: block;
  font-weight: 600;
  src: url(../../../node_modules/@fontsource/source-sans-pro/files/source-sans-pro-latin-600-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/source-sans-pro/files/source-sans-pro-latin-600-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* source-sans-pro-latin-900-normal */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-display: block;
  font-weight: 900;
  src: url(../../../node_modules/@fontsource/source-sans-pro/files/source-sans-pro-latin-900-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/source-sans-pro/files/source-sans-pro-latin-900-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

/* bebas-neue-latin-400-normal */
@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(../../../node_modules/@fontsource/bebas-neue/files/bebas-neue-latin-400-normal.woff2)
      format('woff2'),
    url(../../../node_modules/@fontsource/bebas-neue/files/bebas-neue-latin-400-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}
