@import './custom/mapboxgl.scss';
@import './custom/fonts.scss';
@import '../../node_modules/react-quill/dist/quill.snow.css';

.list {
  user-select: none;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 16px;
}

@media (min-width: 35em) {
  .list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 55em) {
  .list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.listing-image-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 4px;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  font-size: 20px;
  user-select: none;
  flex: 1;
}

.disclaimer-text {
  font-size: 11px;
}

@media (min-width: 600px) {
  .list {
    grid-gap: 24px;
  }
}

#__next {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.yarl__slide_image {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100%;
  height: 100%;
}

.loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 6rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  border-radius: 50%;
  animation: pulsOut 1.8s ease-in-out infinite;
  filter: drop-shadow(0 0 1rem rgba(6, 134, 104, 0.75)); /* Changed to black with some opacity */
}

.loader:before {
  width: 100%;
  padding-bottom: 100%;
  box-shadow: inset 0 0 0 1rem #068668; /* Changed to black */
  animation-name: pulsIn;
}

.loader:after {
  width: calc(100% - 2rem);
  padding-bottom: calc(100% - 2rem);
  box-shadow: 0 0 0 0 #068668; /* Changed to black */
}

@keyframes pulsIn {
  0% {
    box-shadow: inset 0 0 0 1rem #068668; /* Changed to black */
    opacity: 1;
  }
  50%,
  100% {
    box-shadow: inset 0 0 0 0 #068668; /* Changed to black */
    opacity: 0;
  }
}

@keyframes pulsOut {
  0%,
  50% {
    box-shadow: 0 0 0 0 #068668; /* Changed to black */
    opacity: 0;
  }
  100% {
    box-shadow: 0 0 0 1rem #068668; /* Changed to black */
    opacity: 1;
  }
}

// Quill Text Editor
.ql-toolbar,
.ql-container {
  border: none !important;
}
.ql-toolbar {
  border-bottom-color: inherit !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
}
.ql-tooltip {
  z-index: 1000 !important;
  border-color: #e2e8f0 !important;
}

.chakra-collapse:has(.chakra-accordion__panel .quill) {
  overflow: visible !important;
}

.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.rich-text-editor-container {
  ul,
  ol {
    padding: revert;
  }
  li {
    padding: revert;
  }
}

// Marketplace Page Styles - Disable CSSReset from chakra-ui
#marketplace-page {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: revert;
    font-size: revert;
    font-weight: revert;
  }
  p {
    margin: revert;
    font-size: revert;
    font-weight: revert;
  }
  ul,
  ol {
    margin: revert;
    padding: revert;
  }
  li {
    margin: revert;
    padding: revert;
  }
  a {
    color: revert;
    text-decoration: revert;
  }
  blockquote {
    margin: revert;
    padding: revert;
  }
  hr {
    margin: revert;
    padding: revert;
  }
  table {
    margin: revert;
    padding: revert;
    border-collapse: revert;
    border-spacing: revert;
  }
  th {
    margin: revert;
    padding: revert;
    text-align: revert;
  }
  td {
    margin: revert;
    padding: revert;
    text-align: revert;
  }
  pre {
    margin: revert;
    padding: revert;
    font-size: revert;
    font-family: revert;
  }
  code {
    margin: revert;
    padding: revert;
    font-size: revert;
    font-family: revert;
  }
}
